@use 'ui/styles/variables' as vars;

.Container {
  border: 1px solid var(--ui-mutedText);
  border-radius: 4px;
  margin: 0 8px 24px;
  position: relative;
  z-index: 1;
  padding-bottom: 16px;

  @include vars.mediaQuery('sm') {
    margin: 0 40px 24px;
  }
}

.TitleContainer {
  z-index: 2;
  position: absolute;
  margin-top: -20px;
  width: 100%;
  justify-content: center;
  display: flex;
}

.Content {
  margin-top: 24px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.OfferContainer {
  padding: 8px;

  @include vars.mediaQuery('sm') {
    padding: 8px 24px;
  }
}

.OfferCover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(9px);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1;
  border-radius: 4px;
}

.OfferCover .Redeem {
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
}


.Alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px !important;
}

.Plan {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0;
  text-transform: uppercase;
}

.Duration {
  font-size: .95rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.ButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  padding-bottom: 24px;
  align-items: center;

  @include vars.mediaQuery('sm') {
    flex-direction: row;
    justify-content: center;
  }
}

.RedeemBtnWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;

  @include vars.mediaQuery('sm') {
    flex-direction: row;
    justify-content: center;
  }
}

.Title {
  margin: 0;
  background-color: white;
  padding: 0 16px;
}

.Discount {
  background-color: var(--core-red);
  color: white;
  width: fit-content;
  padding: 0 12px !important;
  font-weight: 500;
  margin-bottom: 0;
}

.PriceContainer {
  display: flex;
  margin-top: 12px
}

.Price {
  font-weight: 500;
  font-size: 2.5rem;
  margin: 0 8px;
}

.MinorText {
  color: var(--ui-mutedText);
  text-transform: uppercase;
  margin: 10px 4px 0 !important;
}

.Uppercase {
  text-transform: uppercase;
  color: var(--ui-mutedText);
  white-space: nowrap;
}