@use 'ui/styles/variables' as vars;

.NewsModalHeader {
  border-bottom: 1px solid #e9ecef;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.noTitle {
    justify-content: flex-end;
    border: none;
    padding-bottom: -16px;

    &+.NewsModalBody {
      padding-top: 0px;
    }
  }

  h1.NewsModalTitle {
    color: var(--core-ink);
    font-weight: 600;
    font-size: 28px;
    line-height: 1em;
    padding: 24px 0px 24px 24px;
    margin: 0px;
  }

  .CloseButton {
    padding: 6px 11px;
    position: relative;
    font-size: 38px;

    svg {
      border-radius: 3px;
      padding: 6px;
      width: 38px;
      height: 38px;
    }

    &:focus,
    &:focus-visible {
      outline: 0;

      svg {
        box-shadow: 0 0 0px 2px var(--ui-orange);
      }
    }
  }
}

.NewsModalBody {
  font-size: 14px;
  line-height: 1.4em;
  padding: 24px 24px;
}

.NewsModalFooter {
  border-top: 1px solid #e9ecef;
  background-color: #fff;
  justify-content: end;
  display: flex;
  padding: 24px 24px;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}

.Dialog {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: var(--modal-zindex);
  transition: opacity .15s linear;
  opacity: 0;

  &.center {
    display: flex;
    align-items: center;

    @include vars.mobileOnly() {
      // needed for specificity
      align-items: flex-end;
    }
  }

  @include vars.mobileOnly() {
    display: flex;
    align-items: flex-end;
    transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
    top: auto;
    bottom: -300px;
  }

  &.bottom {
    display: flex;
    align-items: flex-end;
    top: auto;
    bottom: -300px;
    transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
  }


  // transition effects
  &.transition {
    opacity: 1;

    @include vars.mobileOnly() {
      bottom: 0;
    }

    &.bottom {
      bottom: 0;
    }

    .Modal {
      transform: none;
    }
  }
}

.Modal {
  max-width: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  z-index: var(--modal-zindex);
  border-radius: 5px;
  background-color: #fff;
  left: 50%;
  top: 0px;
  transition: transform .3s ease-out;
  transform: translateY(-50px);
  width: calc(100% - 16px);
  margin: 8px auto;

  @include vars.mobileOnly() {
    width: 100% !important;
    margin: 0px !important;
    max-width: inherit !important;
  }

  &.bottom {
    margin-top: auto !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: 100% !important;
    width: 100% !important;
  }

  @include vars.mediaQuery('md') {
    width: calc(100% - 64px);
    margin: 32px auto;
  }

  &.large {
    max-width: 800px;
  }

  &.large {
    max-width: 800px;
  }

  &:focus-visible {
    outline: none;
  }

  * {
    overscroll-behavior-y: contain;
  }

}

.ModalBackdrop {
  background-color: #000;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: var(--modal-zindex) - 1;
  transition: opacity .15s linear;
  opacity: 0;

  &.transition {
    opacity: .5;
  }
}