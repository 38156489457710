.FollowingButton {
  svg {
    display: none;
  }

  &.icons {
    svg {
      display: block;
    }
  }

  &.toggling {
    .onFocus {
      display: flex !important;
      align-items: center !important;
    }

    .offFocus {
      display: none !important;
    }
  }

  .onFocus,
  .offFocus {
    span {
      display: flex;
      flex-direction: column;

      &:after {
        display: block;
        content: attr(data-placeholder);
        font-weight: bold;
        height: 1px;
        color: transparent;
        overflow: hidden;
        visibility: hidden;
      }
    }
  }


  .onFocus {
    display: none;
  }

  .offFocus {
    display: flex;
    align-items: center;
  }

  &:hover,
  &:focus-visible {
    .onFocus {
      display: flex;
      align-items: center;
    }

    .offFocus {
      display: none;
    }
  }
}