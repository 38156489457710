.attribution {
  margin-bottom: 16px;
  color: var(--ui-mutedText);
  text-align: center;

  .shortHeader {
    font-size: .875em;
    text-align: center;
  }

  a {
    display: block;
    text-align: center;


    h3 {
      margin-top: 4px;
      font-size: .875em;
    }
  }

  p {
    text-align: left;
    font-size: .875em;

    a {
      display: inline;
      text-align: inherit;
    }
  }
}