.Container {
  padding: 0 32px 48px 32px;
}

.Content {
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

