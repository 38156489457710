.Container {
  p:last-child {
    display: inline;
  }
}

.ReadMore {

  .hide,
  [aria-expanded] {
    display: none;
  }

  [aria-expanded=true] {
    display: inherit;
  }
}

.ReadMoreButton {
  font-weight: bold;
}