.ModalBody {
  text-align: center;
  position: relative;
  transition: all;
}

.ModalBody img,
.ModalBody canvas {
  width: 100%;
}

.ModalBody .VideoWrap {
  width: 100%;
  border: 1px solid var(--bs-secondary-border-subtle);
  display: flex;
  position: relative;
}

.ModalBody video {
  width: 100%;
}

.Footer {
  display: flex;
  justify-content: space-between;
}

.Buttons {
  display: flex;
  gap: 0.5rem;
}

.CountDown {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.3);
  margin: 0;
  padding: 0;
}

.CountDown li {
  list-style: none;
  padding: 0;
  opacity: 0;
  margin-top: -1rem;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  position: absolute;
  color: #fff;
  font-size: 72px;
  text-shadow: 0 0 1px #000
}

.CountDown li:nth-of-type(1) {
  -webkit-animation: countdown 10s ease-in-out 0s 6;
  -moz-animation: countdown 10s ease-in-out 0s 6;
  -ms-animation: countdown 10s ease-in-out 0s 6;
  animation: countdown 10s ease-in-out 0s 6
}

.CountDown li:nth-of-type(2) {
  -webkit-animation: countdown 10s ease-in-out 1s 6;
  -moz-animation: countdown 10s ease-in-out 1s 6;
  -ms-animation: countdown 10s ease-in-out 1s 6;
  animation: countdown 10s ease-in-out 1s 6
}

.CountDown li:nth-of-type(3) {
  -webkit-animation: countdown 10s ease-in-out 2s 6;
  -moz-animation: countdown 10s ease-in-out 2s 6;
  -ms-animation: countdown 10s ease-in-out 2s 6;
  animation: countdown 10s ease-in-out 2s 6
}

@-webkit-keyframes countdown {
  0% {
    opacity: 1
  }

  9.9% {
    opacity: 1
  }

  10% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

@keyframes countdown {
  0% {
    opacity: 1
  }

  9.9% {
    opacity: 1
  }

  10% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}