@use 'ui/styles/variables' as vars;

.Container {
  display: flex;
  flex-direction: column;
  padding-top: 3.5rem;

  @include vars.mobileOnly() {
    padding-top: 1.5rem;
  }
}

.InnerContainer {
  margin: 0 16px;
}

.ButtonContainer {
  display: flex;
  gap: 20px;
  margin-top: 35px;

  @include vars.mobileOnly() {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
}

.List {
  margin-bottom: 32px;

  @include vars.mobileOnly() {
    margin-bottom: 24px
  }

  li {
    margin-bottom: 0;

    p {
      margin-bottom: 0.2rem;
    }
  }
}

.MessageHeader {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 1em;
}

.FeedbackContainer {
  width: 100%;
}

.Form {
  margin-bottom: 12px
}

.FeedbackOptions {
  padding: 0;
}

.FormLabel {
  margin-bottom: 4px;
}